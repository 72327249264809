<template>
  <v-card flat class="fill-height box">
    <v-app-bar flat class="row header mx-0">
      <img :src="require('../assets/LeonardoIcon.svg')" width="75" />
      <v-divider class="mx-3" inset vertical></v-divider>
      <span class="headline">Rental Shop</span>

    </v-app-bar>
    <v-card-text :class="$vuetify.breakpoint.smAndDown ? 'mx-0' : 'row content mx-0'">
    
      <v-row align-content="center" justify="center">
        <v-col cols="10" md="8" lg="6">
          <div class="headline pl-0">When you'll be using your outfit</div>
          <v-card-subtitle class="pt-0 pl-0">We need to know when you'll be wearing the outfit</v-card-subtitle>

          <v-row>
            <v-col cols="12" md="4">
              <v-select v-model="month" :items="months" outlined placeholder="Month" :error-messages="monthErrors"></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select v-model="day" :items="days" outlined placeholder="Day" :error-messages="dayErrors"></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select v-model="year" :items="years" outlined placeholder="Year" :error-message="yearErrors"></v-select>
              <!-- <v-text-field v-model="year" outlined placeholder="Year" :error-messages="yearErrors" v-mask="'####'" hint="format: YYYY"></v-text-field> -->
            </v-col>
          </v-row>

          <div class="headline pl-0 mt-5">Enter your height and weight</div>
          <v-card-subtitle class="pt-0 pl-0">We'll use this information to calculate your measurements</v-card-subtitle>

          <v-row>
            <v-col cols="12" md="4">
              <v-select v-model="heightFeet" :items="feet" outlined placeholder="Height (feet)" :error-messages="feetErrors"></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select v-model="heightInches" :items="inches" outlined placeholder="Height (inches)" :error-messages="inchesErrors"></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="weight" outlined placeholder="Weight" :error-messages="weightErrors" suffix="lbs" v-mask="'###'"></v-text-field>
            </v-col>
          </v-row>

          <div class="headline pl-0 mt-5">Select your outfit fit</div>
          <v-card-subtitle class="pt-0 pl-0">This will help us calculate your measurements</v-card-subtitle>

          <v-row>
            <v-col cols="12" md="6">
              <v-card flat @click="fit = 'slim'" :class="fit == 'slim' ? 'fit' : ''">
                <v-card-text class="text-center">
                  <img :src="require('../assets/images/slimfit.svg')" style="height:150px;" />
                  <div class="title">Slim fit</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card flat @click="fit = 'classic'" :class="fit == 'classic' ? 'fit' : ''">
                <v-card-text class="text-center">
                  <img :src="require('../assets/images/modernfit.svg')" style="height:150px;" />
                  <div class="title">Classic fit</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-btn absolute bottom right depressed x-large color="primary" @click="nextStep" :loading="loading">
        <v-icon left>mdi-arrow-right</v-icon> Next
      </v-btn>

    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import moment from 'moment';
import { _st } from '@/softech';

export default {
    data: () => ({
      month: '',
      monthErrors: '',
      day: '',
      dayErrors: '',
      year: '',
      yearErrors: '',

      months: [
          { value: '01', text: 'January' },
          { value: '02', text: 'February' },
          { value: '03', text: 'March' },
          { value: '04', text: 'April' },
          { value: '05', text: 'May' },
          { value: '06', text: 'June' },
          { value: '07', text: 'July' },
          { value: '08', text: 'August' },
          { value: '09', text: 'September' },
          { value: '10', text: 'October' },
          { value: '11', text: 'November' },
          { value: '12', text: 'December' },
      ],
      days: ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],

      feet: ['3','4','5', '6','7'],
      inches: ['0','1','2','3','4','5','6','7','8','9','10','11'],
      heightFeet: '',
      feetErrors: '',
      heightInches: '',
      inchesErrors: '',
      weight: '',
      weightErrors: '',
      fit: 'slim',

      loading: false,
    }),
    computed: {
      ...mapGetters({
        sUseDate      : 'useDate',
        sHeightFeet   : 'heightFeet',
        sHeightInches : 'heightInches',
        sWeight       : 'weight',
        sFit          : 'fit',
      }),

      years() {
        let years = [];
        let current = ( new Date() ).getFullYear();

        let ix = 0;
        do {
          years.push( ( current + ix ).toString() );
          ix++;
        } while( ix <= 3 );

        return years;
      }
    },
    mounted() {
      // if( !this.isTokenValid() )
      //   this.$router.push( '/unauthorized' );

      this.heightFeet = this.sHeightFeet??'';
      this.heightInches = this.sHeightInches??'';
      this.weight = this.sWeight??'';
      this.fit = this.sFit??'slim';

      if( !_st.isNUE( this.sUseDate ) ) {
        let d = this.sUseDate.split('-');
        this.year = d[0];
        this.month = d[1];
        this.day = d[2];
      }
    },
    methods: {
      ...mapActions({
        setUseDate        : 'setUseDate',
        setHeightWeightFit: 'setHeightWeightFit'
      }),
      isValid() {
        let errorFound = false;
        this.monthErrors = this.dayErrors = this.yearErrors = this.weightErrors = 
        this.feetErrors = this.inchesErrors = '';

        if( _st.isNUE( this.month ) ) {
          this.monthErrors = 'required';
          errorFound = true;
        }
        else if( parseInt( this.month ) < 1 || parseInt( this.month ) > 12 ) {
          this.monthErrors = 'must be between January and December';
          errorFound = true;
        }

        if( _st.isNUE( this.day ) ) {
          this.dayErrors = 'required';
          errorFound = true;
        }
        else if( parseInt( this.day ) < 1 ) {
          this.dayErrors = 'must be greater than 0';
          errorFound = true;
        }

        if( _st.isNUE( this.year ) ) {
          this.yearErrors = 'required';
          errorFound = true;
        }
        else if( this.year.length != 4 ) {
          this.yearErrors = 'valid format is YYYY';
          errorFound = true;
        }

        if( _st.isNUE( this.weight ) ) {
          this.weightErrors = 'required';
          errorFound = true;
        }

        if( _st.isNUE( this.heightFeet ) ) {
          this.feetErrors = 'required';
          errorFound = true;
        }

        if( _st.isNUE( this.heightInches ) ) {
          this.inchesErrors = 'required';
          errorFound = true;
        }

        if( !errorFound ) {
          let date = this.year + '-' + this.month + '-' + this.day;
          date = moment( date );

          if( !date.isValid() ) {
            this.mxShowMessage( 'Error', 'Your must enter a valid date', 'error' );
            errorFound = true;
          }
          else if( date.isBefore( moment().add(7, 'days') ) ) {
            this.mxShowMessage( 'Error', 'Your use date must be at least 8 days from today', 'error' );
            errorFound = true;
          }
        }

        return !errorFound;
      },
      nextStep() {
        if( !this.isValid() )
          return;

        let date = this.year + '-' + this.month + '-' + this.day;
        this.setUseDate( date );
        this.setHeightWeightFit({ 
          heightFeet: this.heightFeet,
          heightInches: this.heightInches,
          weight: this.weight,
          fit: this.fit
        });

        this.$router.push( '/promo-code' );
      },
    },
    directives: { mask }
}
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  flex-flow: column;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.fit {
  background-color: #ececec;
}
</style>